<template>
  <div class="relative">
    <div class="mb-2 flex items-center justify-between lg:mb-4">
      <div class="inline-flex flex-1 items-center gap-4 truncate">
        <img
          v-if="isImageUrl"
          class="icon-main-pink size-5 object-contain"
          :src="svgIcon"
          alt=""
          loading="lazy"
        />
        <NavbarSvg v-else :item="svgIcon" :active="true" />
        <NuxtLink
          :to="link"
          class="mr-3 truncate text-sm font-extrabold capitalize text-white md:text-lg lg:text-2xl xl:font-bold xl:tracking-normal"
          aria-label="title"
        >
          {{ title }}
        </NuxtLink>
      </div>
      <div class="flex items-center gap-2 sm:gap-3">
        <NuxtLink
          :to="link ? link : ''"
          v-if="Number(listData?.length) >= 10"
          class="px-sm-3 flex h-9 min-w-24 items-center justify-center rounded-full bg-main-125 px-2 text-xs font-medium text-white sm:!h-11 sm:min-w-24 md:!text-sm lg:text-sm"
        >
          {{ $t("common.view_all") }}
        </NuxtLink>
        <v-icon
          aria-label="prev-icon"
          v-if="showNavigation"
          size="small"
          class="!size-9 cursor-pointer rounded-full bg-main-125 sm:!size-11"
          @click="slider?.prev()"
        >
          mdi-chevron-left
        </v-icon>
        <v-icon
          @click="slider?.next()"
          aria-label="next-icon"
          v-if="showNavigation"
          size="small"
          class="!size-9 cursor-pointer rounded-full bg-main-125 sm:!size-11"
        >
          mdi-chevron-right
        </v-icon>
      </div>
    </div>

    <div
      v-if="!listData?.length && showNotFound"
      class="-mx-1 flex h-[210px] flex-wrap transition-all lg:-mx-2 lg:h-[302px] lg:flex-nowrap"
    >
      <div
        class="mx-2 flex h-full w-full flex-col items-center justify-center rounded-2xl bg-main-150"
      >
        <img
          src="/images/icon/icon-not-found.png"
          alt="icon-not-found"
          class="mb-6"
        />
        <span class="text-sm text-white">
          {{ $t("common.message.no_game_found") }}
        </span>
      </div>
    </div>

    <div ref="container" class="keen-slider w-full">
      <slot />
    </div>
  </div>
</template>
<script setup lang="ts">
import { KeenSliderOptions } from "keen-slider/vue.es";

type Props = {
  title: string;
  showNotFound: boolean;
  listData: any[];
  id: string;
  link: string;
  svgIcon: any;
  slidersOption?: KeenSliderOptions;
};
const props = withDefaults(defineProps<Partial<Props>>(), {
  slidersOption: {} as any,
});

const isImageUrl = computed(() => {
  return typeof props.svgIcon === "string";
});
const current = ref(0);

const [container, slider] = useKeenSlider({
  slides: {
    perView: "auto",
  },
  initial: current.value,
  slideChanged: (s) => {
    current.value = s.track.details.rel;
  },
  ...props.slidersOption,
});

const showNavigation = ref(false);

const updateNavigationVisibility = () => {
  if (container.value) {
    showNavigation.value =
      container.value.scrollWidth > container.value.clientWidth;
  }
};

let resizeObserver: ResizeObserver | null = null;
if (isClient()) {
  watch(
    container,
    () => {
      resizeObserver?.disconnect();
      resizeObserver = new ResizeObserver(updateNavigationVisibility);
      if (container.value) {
        resizeObserver.observe(container.value);
      }
      updateNavigationVisibility();
    },
    {
      immediate: true,
    }
  );
  watch(
    showNavigation,
    () => {
      slider.value?.update({
        ...slider.value.options,
        ...props.slidersOption,
        loop:
          typeof props.slidersOption?.loop === "boolean"
            ? props.slidersOption.loop
            : showNavigation.value,
      });
    },
    { immediate: true }
  );
}
</script>

<style scoped lang="scss">
.icon-main-pink {
  filter: invert(53%) sepia(59%) saturate(6402%) hue-rotate(291deg)
    brightness(101%) contrast(98%);
}
</style>
